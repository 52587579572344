import React from 'react';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import SenderPage from '../../templates/Sender';

const data = {
  key: '2VLcsjTFbwTEHN3vAPXaXVvhUU',
  companyId: 'i441268392',
  title: 'Junior certification'
};

const Junior = (props) => (
  <Layout {...props}>
    <SenderPage data={data}/>
  </Layout>
);

export default withIntl(Junior);

